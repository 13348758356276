import React, { useMemo, useEffect, memo } from "react";

import PropTypes from "prop-types";

import PhpRenderer from "./PhpRenderer";

function PhpRendererBase({ hostId, contentId }) {
  const uniqId = useMemo(() => Math.random(), []);
  useEffect(() => {
    const contentNode = document.getElementById(contentId);
    const hostNode = document.getElementById(`${hostId}-${uniqId}`);
    const intv = setInterval(() => {
      const isVisible = !!document
        .getElementById(`PhpRendererBase-visibility-${uniqId}`)
        .getBoundingClientRect().width;
      if (isVisible && contentNode && contentNode.parentNode !== hostNode) {
        hostNode.appendChild(contentNode);
      }
    }, 100);
    return () => clearInterval(intv);
  }, [contentId, hostId, uniqId]);

  const styles = useMemo(
    () => ({
      width: 1,
      height: 1,
    }),
    []
  );

  return (
    <>
      <PhpRenderer hostId={`${hostId}-${uniqId}`} />
      <div id={`PhpRendererBase-visibility-${uniqId}`}>
        <div style={styles} />
      </div>
    </>
  );
}

PhpRendererBase.propTypes = {
  hostId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
};

export default memo(PhpRendererBase);
