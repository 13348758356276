import { useCallback, useMemo, useState } from "react";

import { useFetcher } from "@mobsuccess-devops/react-data-context";
import { useMediaQuery } from "@mobsuccess-devops/react-ui";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import { useRouteMatch } from "react-router-dom";
import { useTheme } from "styled-components";

import { getServerHostForEntity } from "../api/config";
import { axiosGet } from "../api/msApi";

export function useQueryNavbar() {
  const [needsRedirectToLogin, setNeedsRedirectToLogin] = useState(false);

  const navbar = useFetcher("dashboard-v2/navbar", async () => {
    try {
      return (
        await axiosGet({
          entity: "dashboardNavbar",
          verb: "get",
          params: { version: 2 },
        })
      ).data;
    } catch (e) {
      if (e.response?.status === 401) {
        setNeedsRedirectToLogin(true);
        window.location = `${getServerHostForEntity({
          entity: "dashboardNavbar",
        })}/dashboard_new/go.php?url=${encodeURIComponent(window.location)}`;
      }
    }
  });

  if (needsRedirectToLogin) {
    return;
  }
  return navbar;
}

export function useClientName() {
  const navbar = useQueryNavbar();

  const clientName = useMemo(() => {
    return navbar?.account?.account_name.toLowerCase();
  }, [navbar]);

  return clientName;
}

export function useNavbarState({ isRetracted }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoint.ipad})`, {
    noSsr: true,
  });

  const item = window.localStorage.getItem("navbarOpen");
  const url = useRouteMatch();

  const [open, setOpen] = useState(() => {
    try {
      if (!isDesktop) {
        return false;
      }
      if (isRetracted) {
        return false;
      }
      if (url.path.includes("/lmp")) {
        const resetLMPSidebar = window.localStorage.getItem("resetLMPSidebar");
        if (resetLMPSidebar && JSON.stringify(resetLMPSidebar)) {
          return JSON.parse(item);
        } else {
          return false;
        }
      }
      if (item) {
        return JSON.parse(item);
      } else {
        window.localStorage.setItem("navbarOpen", JSON.stringify(true));
        return true;
      }
    } catch (error) {
      return false;
    }
  });

  const setValue = useCallback(
    (value) => {
      setOpen((open) => {
        const valueToStore = value instanceof Function ? value(open) : value;
        if (isDesktop) {
          try {
            window.localStorage.setItem(
              "navbarOpen",
              JSON.stringify(valueToStore)
            );
            if (url.path.includes("/lmp")) {
              window.localStorage.setItem(
                "resetLMPSidebar",
                JSON.stringify(true)
              );
            }
          } catch {}
        }
        return valueToStore;
      });
    },
    [setOpen, isDesktop, url]
  );

  return [open, setValue, isDesktop];
}

export const useHeaderState = () => {
  const [hideTop, setHideTop] = useState(false); // campaign header
  const [hiddenHeader, setHiddenHeader] = useState(false); // main header
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      if (!hideTop) {
        setHideTop(true);
      }

      if (prevPos.y < currPos.y) {
        if (hiddenHeader) {
          setHiddenHeader(false);
        }
      } else {
        if (!hiddenHeader) {
          setHiddenHeader(true);
        }
      }
    } else {
      if (prevPos.y * -1 + currPos.y !== 32) {
        setHideTop(false);
        setHiddenHeader(false);
      }
    }
  });
  return [hideTop, hiddenHeader];
};
