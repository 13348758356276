import { memo, useCallback, useEffect, useState } from "react";

import { DataContext } from "@mobsuccess-devops/react-data-context";

import PropTypes from "prop-types";

import { getServerHostForEntity } from "../../features/api/config";
import { getConfig } from "../../features/query/query";

import GeneratePlatformAuth from "./GeneratePlatformAuth";

function DataContextProviderBase({ children, data }) {
  const [dataContextConfig, setDataContextConfig] = useState(null);

  const handleChangeDataContextConfig = useCallback(async () => {
    const { queryBranch, dataEnvironment } = await getConfig();
    setDataContextConfig({
      queryBranch,
      dataEnvironment,
      host: getServerHostForEntity("query"),
    });
  }, []);

  useEffect(() => {
    handleChangeDataContextConfig();
  }, [handleChangeDataContextConfig]);

  if (!dataContextConfig) {
    return null;
  }

  return (
    <DataContext data={data} config={dataContextConfig}>
      <GeneratePlatformAuth>{children}</GeneratePlatformAuth>
    </DataContext>
  );
}

DataContextProviderBase.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};

DataContextProviderBase.defaultProps = {
  children: null,
  data: undefined,
};

export default memo(DataContextProviderBase);
