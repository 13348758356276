import { memo, useEffect, useState } from "react";

import { useSetAuthorization } from "@mobsuccess-devops/react-data-context";

import PropTypes from "prop-types";

import { getServerHostForEntity } from "../../../features/api/config";
import { axiosPost } from "../../../features/api/msApi";

function GeneratePlatformAuthBase({ children }) {
  const setAuthorization = useSetAuthorization();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const authToken = (
          await axiosPost({
            entity: "auth",
            verb: "generate",
            params: {
              environment:
                process.env.REACT_APP_PLATFORM_AUTH_ENVIRONMENT || "dev",
            },
          })
        ).data;
        setAuthorization(authToken.AccessToken);
        setIsReady(true);
      } catch (e) {
        if (e.response?.status === 401) {
          window.location = `${getServerHostForEntity({
            entity: "dashboardNavbar",
          })}/dashboard_new/go.php?url=${encodeURIComponent(window.location)}`;
        }
      }
    })();
  }, [setAuthorization]);
  return !isReady ? null : children;
}

GeneratePlatformAuthBase.propTypes = {
  children: PropTypes.node,
};

GeneratePlatformAuthBase.defaultProps = {
  children: null,
};

export default memo(GeneratePlatformAuthBase);
