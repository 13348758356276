function getServerHostOverride() {
  if (Number(process.env.REACT_APP_AMPLIFY)) {
    return window.location.hostname.match(/^localhost/)
      ? window.location.origin
      : window.location.pathname.match(/^\/components\//) ||
        [
          "dev.mobsuccess.com",
          "dashboard.gifi-clickandcollect.fr",
          "dashboard-decathlon.mobsuccess.com",
          "preprod.mobsuccess.com",
        ].indexOf(window.location.hostname) >= 0
      ? `${window.location.protocol}//${window.location.hostname}`
      : "https://www.mobsuccess.com";
  }
  if (
    window.location.host === "localhost_dev_components.mobsuccess.com" ||
    window.location.host === "dev_components.mobsuccess.com"
  ) {
    return "https://www.mobsuccess.com";
  }
  return null;
}
function getServerHostFromLocalStorage() {
  try {
    const host = localStorage.getItem("REACT_APP_MS_API_HOST");
    return host || null;
  } catch (e) {
    return null;
  }
}

/*
 * Returns the host of the backend server to handle Drive API requests.
 *
 * This is the default backend server, unless we are on a development environment, and need to query the preprod PHP backend.
 */
function getServerHostForDrive() {
  if (
    window.location.hostname.match(/^[a-z0-9-]*.components.live.mobsuccess.com/)
  ) {
    return "https://preprod.mobsuccess.com";
  }

  return getDefaultServerHost();
}

/**
 * Returns the default backend server to handle requests.
 */
function getDefaultServerHost() {
  return (
    getServerHostFromLocalStorage() ||
    getServerHostOverride() ||
    (process.env.NODE_ENV === "production" &&
    !window.location.hostname.match(/integrate/)
      ? ""
      : process.env.REACT_APP_MS_API_HOST || "http://localhost:3008")
  );
}

/*
 * Returns the host of the backend server that should be used to query the given entity.
 */
export function getServerHostForEntity({ entity }) {
  switch (entity) {
    case "driveJsonData":
    case "driveOrder":
      return getServerHostForDrive();
    default:
      return getDefaultServerHost();
  }
}
