import React, { memo, useMemo, useCallback } from "react";

import { Sidebar } from "@mobsuccess-devops/react-ui";
import { ReactComponent as LayoutModuleIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/38-Layouts/layout-module-1.svg";

import mobsuccessLogo from "../../assets/logos/mobsuccessLogo.png";
import { useQueryNavbar } from "../../features/navigation/state";

function SidebarProviderBase() {
  const accountInfos = useQueryNavbar();

  const sidebarConfig = useMemo(() => {
    if (!accountInfos) {
      return null;
    }

    return {
      account: {
        id: accountInfos.account.account_id,
        name: accountInfos.account.account_name,
      },
      user: {
        admin: accountInfos.user.admin,
        firstname: accountInfos.user.user_firstname,
      },
      footerMenu: {
        logout: {
          label: "Se déconnecter",
          url: accountInfos.footerMenu.logoutUrl,
        },
        admin: {
          label: "Administration",
          url: accountInfos.footerMenu.adminUrl,
        },
        pages: accountInfos.footerMenu.pages,
      },
    };
  }, [accountInfos]);

  const services = useMemo(() => {
    if (!accountInfos) {
      return null;
    }
    return accountInfos.sections.map((section) => ({
      ...section,
      current: window.location.pathname.startsWith(section.pageUrl),
    }));
  }, [accountInfos]);

  const currentService = useMemo(() => {
    return accountInfos?.sections.find(({ pageUrl }) =>
      window.location.pathname.startsWith(pageUrl)
    );
  }, [accountInfos]);

  const pages = useMemo(() => {
    if (!currentService) {
      return [];
    }

    // Test if one of the pages that is not root is already defined as current
    let subpageMatch = false;
    const reversedPages = Array.from(currentService?.pages ?? []).reverse();
    const pages =
      reversedPages.map((page) => {
        const path = String(page.pageUrl).replace(currentService.pageUrl, "");
        const current =
          !subpageMatch &&
          window.location.pathname
            .replace(/\/$/, "")
            .startsWith(String(page.pageUrl).replace(/\/$/, ""));

        if (current) {
          subpageMatch = true;
        }

        return {
          label: page.pageText,
          path: path.length ? path : "/",
          current: current,
          iconSrc: page.pageIcon,
          Icon: LayoutModuleIcon,
        };
      }) ?? [];

    return Array.from(pages).reverse();
  }, [currentService]);

  const handleNavigate = useCallback(
    ({ service, page }) => {
      if (page) {
        window.location = `${currentService.pageUrl}${page}`;
      } else if (service) {
        window.location = service;
      }
    },
    [currentService]
  );

  return !accountInfos ? null : (
    <Sidebar
      config={sidebarConfig}
      pages={pages}
      services={services}
      logo={mobsuccessLogo}
      onNavigate={handleNavigate}
      defaultState={window._msSidebarState}
    />
  );
}

export default memo(SidebarProviderBase);
