import { memo } from "react";

import { scripts, useScript } from "../../../features/scripts/scripts";

const hjid =
  "REACT_APP_HJID" in process.env ? process.env.REACT_APP_HJID : "2694637";

const hotjarScript = scripts.js`
(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${hjid},hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;

function HotjarBase() {
  useScript({
    javascript: hotjarScript,
  });

  return null;
}

export default memo(HotjarBase);
