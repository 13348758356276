import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const LayoutUI = styled.div`
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.ipad}) {
    height: calc(100vh - 64px);
  }
`;

LayoutUI.displayName = "LayoutUI";

function Layout({ children }) {
  return <LayoutUI>{children}</LayoutUI>;
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default memo(Layout);
