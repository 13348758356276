/* eslint-disable @mobsuccess-devops/mobsuccess/layout */
import axios from "axios";

import { getServerHostForEntity } from "./config";

function getURI({ entity, verb = "list", params }) {
  return `${getServerHostForEntity({
    entity,
  })}/webservices/rest/${verb}-${entity}${
    params
      ? `?${Object.entries(params)
          .map(
            ([name, value]) =>
              `${encodeURIComponent(name)}=${encodeURIComponent(
                Array.isArray(value) ? JSON.stringify(value) : value
              )}`
          )
          .join("&")}`
      : ""
  }`;
}

const runningRequests = {};

export async function axiosGet({ entity, verb, params }) {
  const url = getURI({ entity, verb, params });
  if (runningRequests[url]) {
    return await runningRequests[url];
  }
  const promise = axios({
    method: "get",
    url,
    withCredentials: true,
  });
  runningRequests[url] = promise;
  const result = await promise;
  delete runningRequests[url];
  return result;
}

export async function axiosPost({ entity, verb, params }) {
  const url = getURI({ entity, verb, params });
  if (runningRequests[url]) {
    return await runningRequests[url];
  }
  const promise = axios({
    method: "post",
    url,
    withCredentials: true,
  });
  runningRequests[url] = promise;
  const result = await promise;
  delete runningRequests[url];
  return result;
}
