import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const PhpRendererUI = styled.div`
  position: relative;
  overflow: scroll;
  width: 100%;

  @media print {
    overflow: revert;
    width: auto;
  }
`;

PhpRendererUI.displayName = "PhpRendererUI";

function PhpRenderer({ hostId }) {
  return <PhpRendererUI id={hostId}></PhpRendererUI>;
}

PhpRenderer.propTypes = {
  hostId: PropTypes.string.isRequired,
};

export default memo(PhpRenderer);
