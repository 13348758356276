import { useMemo, useState } from "react";

export async function getBranch() {
  try {
    const dummyVariableForWebpack = ".json";
    const { default: data } = await import(
      "../../gitinfo" + dummyVariableForWebpack
    );
    return data.branch;
  } catch (e) {
    return null;
  }
}

export function useBranch() {
  const [branch, setBranch] = useState(null);
  useMemo(async () => {
    const branch = await getBranch();
    if (branch) {
      setBranch(branch);
    }
  }, [setBranch]);
  return branch;
}
