import React from "react";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { render } from "react-dom";

import App from "./App";
import DataContextProvider from "./components/DataContextProvider";
import PlatformProvider from "./components/PlatformProvider";
import ServiceContextsProvider from "./components/ServiceContextsProvider";
import { entrypoint } from "./features/constants/entrypoint";
import "./index.css";
import { unregister } from "./serviceWorker";

render(
  <React.StrictMode>
    <DataContextProvider>
      <PlatformProvider>
        <ServiceContextsProvider>
          <App />
        </ServiceContextsProvider>
      </PlatformProvider>
    </DataContextProvider>
  </React.StrictMode>,
  window.top.document.getElementById(`react_root--${entrypoint}`)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
