import React, { memo } from "react";

import { Switch } from "react-router-dom";

import PhpEndpointProvider from "../PhpEndpointProvider";

import Route from "./Route";

function RouterBase() {
  return (
    <Switch>
      <Route path="/">
        <PhpEndpointProvider />
      </Route>
    </Switch>
  );
}

export default memo(RouterBase);
