import React from "react";

import { initSentry } from "@mobsuccess-devops/react-sentry";

import Router from "./components/Router";

function getSentryDsnFromEnv() {
  const dsnByUrlMatch = JSON.parse(
    process.env.REACT_APP_SENTRY_DSN_BY_URL_MATCH ?? "[]"
  );
  const { dsn } =
    dsnByUrlMatch.find(({ match }) =>
      window.location.href.match(new RegExp(match))
    ) ?? {};
  return dsn;
}

const dsn =
  window._msSentryDsn ||
  getSentryDsnFromEnv() ||
  "https://e79df8681da04521b5f7dd8ee172d903@o1028474.ingest.sentry.io/6525257";

initSentry({
  dsn,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
});

function App() {
  return <Router />;
}

export default App;
