import { memo } from "react";

import PropTypes from "prop-types";

function ServiceContextsProviderBase({ children }) {
  return children;
}

ServiceContextsProviderBase.propTypes = {
  children: PropTypes.node,
};

ServiceContextsProviderBase.defaultProps = {
  children: null,
};

export default memo(ServiceContextsProviderBase);
