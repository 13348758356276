import React, { memo } from "react";
import { useMemo } from "react";

import { useMediaQuery } from "@mobsuccess-devops/react-ui";

import { useTheme } from "styled-components";

import PhpEndpointProvider from "./PhpEndpointProvider";

function PhpEndpointProviderBase() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoint.ipad})`, {
    noSsr: true,
  });

  const flexDirection = useMemo(() => (isDesktop ? "row" : "column"), [
    isDesktop,
  ]);

  return <PhpEndpointProvider flexDirection={flexDirection} />;
}

export default memo(PhpEndpointProviderBase);
