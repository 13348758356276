import React, { memo } from "react";

import { ParamProvider } from "@mobsuccess-devops/react-router-dom-search-params";
import {
  NotificationWrapper,
  ThemeProvider,
} from "@mobsuccess-devops/react-ui";

import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";

import { useClientName } from "../../features/navigation/state";

import Hotjar from "./Hotjar";
import Localized from "./Localized";

const keepParams = ["lang", "client", "id_account"];
const basename = typeof window._msBaseUrl === "string" ? window._msBaseUrl : "";

function PlatformProviderBase({ children }) {
  const clientName = useClientName();
  return !clientName ? null : (
    <ThemeProvider client={clientName}>
      <NotificationWrapper>
        <BrowserRouter basename={basename}>
          <ParamProvider keep={keepParams}>
            <Localized>
              <Hotjar />
              {children}
            </Localized>
          </ParamProvider>
        </BrowserRouter>
      </NotificationWrapper>
    </ThemeProvider>
  );
}

PlatformProviderBase.propTypes = {
  children: PropTypes.node,
};

PlatformProviderBase.defaultProps = {
  children: null,
};

export default memo(PlatformProviderBase);
