import { getBranch } from "../gitinfo/gitinfo";

import config from "./config";

async function getConfigFromBranchName() {
  const branch = await getBranch();
  if (!branch) {
    return {};
  } else if (branch.match(/^pr-/)) {
    return Object.values(config).find((x) => `pr-${x.pr}` === branch) || {};
  } else {
    return config[branch] || {};
  }
}

function getConfigFromSearchParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const queryBranch = searchParams.get("queryBranch");
  const dataEnvironment = searchParams.get("dataEnvironment");
  return { queryBranch, dataEnvironment };
}

export async function getConfig() {
  const {
    queryBranch: queryBranchFromBranchName,
    dataEnvironment: dataEnvironmentFromBranchName,
  } = await getConfigFromBranchName();
  const {
    queryBranch: queryBranchFromSearchParams,
    dataEnvironment: dataEnvironmentFromSearchParams,
  } = await getConfigFromSearchParams();
  return {
    queryBranch: queryBranchFromSearchParams || queryBranchFromBranchName,
    dataEnvironment:
      dataEnvironmentFromSearchParams || dataEnvironmentFromBranchName,
  };
}
