import React, { memo } from "react";

import { Container } from "@mobsuccess-devops/react-ui";

import PropTypes from "prop-types";
import styled from "styled-components";

import Layout from "../Layout";
import PhpRenderer from "../PhpRenderer";
import SidebarProvider from "../SidebarProvider";

const PhpEndpointProviderUI = styled(Container)`
  height: 100%;
`;

PhpEndpointProviderUI.displayName = "PhpEndpointProviderUI";

function PhpEndpointProvider({ flexDirection }) {
  return (
    <Layout>
      <PhpEndpointProviderUI flex flexDirection={flexDirection}>
        <SidebarProvider />
        <PhpRenderer hostId="phprendererhost" contentId="phpcontent" />
      </PhpEndpointProviderUI>
    </Layout>
  );
}

PhpEndpointProvider.propTypes = {
  flexDirection: PropTypes.string,
};

PhpEndpointProvider.defaultProps = {
  flexDirection: "column",
};

export default memo(PhpEndpointProvider);
