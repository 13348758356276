import { memo } from "react";

import PropTypes from "prop-types";
import { Route } from "react-router-dom";

function RouteBase({ children, path, Container }) {
  return Container ? (
    <Route path={path}>
      <Container>{children}</Container>
    </Route>
  ) : (
    <Route path={path}>{children}</Route>
  );
}

RouteBase.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  Container: PropTypes.elementType,
};

RouteBase.defaultProps = {
  Container: null,
};

export default memo(RouteBase);
